import { useEffect, useState } from 'react'
import * as S from './styles'
import { Button, Radio, RadioGroup, FormControlLabel, FormControl } from '@material-ui/core'
import { LoadingSpinner } from 'components/shared/LoadingSpinner'
import { getVocClient, numberWithCommas } from '../utils'
import { useLazyQuery, useQuery } from '@apollo/client'
import { GET_CSV_EXPORT_RECORD, GET_FEEDBACK_CSV } from '../queries'
import { getPathValue } from '../../shared/FilterMenu/VoCActivationMethodPathFilter'
import isArray from 'lodash/isArray'
import first from 'lodash/first'
import isEmpty from 'lodash/isEmpty'
import { DownloadCSVProps } from './types'
import useVocState from 'hooks/useVocState'
import useFilterValues from 'hooks/useFilterValues'
import { isNil } from 'lodash'
import { Alert } from '@material-ui/lab'

type ColumnPreference = 'allColumns' | 'visibleColumns'

const CSV_STATUS_POLLING_INTERVAL = 6000 // 6 seconds

export default function DownloadCSVModal({
  open,
  setOpen,
  client,
  fileName,
  category,
  manualCategory,
  source,
  searchBy,
  searchText,
  isReviewed,
  includeNullVerbatims,
  isUserSelectedCategory,
  isFlagged,
  isMobileUser,
  reroutePage,
  surveyID,
  minVerbatimLength,
  maxVerbatimLength,
  appVersion,
  count = 0,
  includeHidden = false,
  activeColumnHeaders,
  csvExportInProgress,
  setCSVExportInProgress,
  csvExportRecordID,
  setCSVExportRecordID,
  csvExportURL,
  setCSVExportURL,
  errorMessage,
  setErrorMessage,
  pageName,
  accountType,
  billerType,
  billingServices,
  userRole,
  accountExpired,
  autoPayStatus,
  footprint,
  loggedInStatus,
  SCP,
  subscribedServices,
  userSelectedCategories,
  _id,
  activatedExperimentUuids,
  experimentUuids,
  variantUuids,
  language,
  middleCategory,
}: DownloadCSVProps) {
  const { state } = useVocState()
  const { applicationIDs } = state
  const {
    environment,
    period,
    showPII,
    CSAT,
    NPS,
    starRating,
    starRatingExternal,
    SR,
    awsSentimentPositive,
    awsSentimentNegative,
    userType,
    activationMethod,
  } = useFilterValues()
  const csvClient = getVocClient(environment as string, true) // no cache and extended timeout
  const formattedcategories = isArray(category) ? { category } : category
  const formattedCategoryFilter = category && !isEmpty(category) ? formattedcategories : {}
  const manualCategoryFilter = manualCategory ? { manualCategory } : {}
  const sourceQuery = source ? { source } : {}
  const showPIIFilterVal = isArray(showPII) ? first(showPII) : showPII
  const showPIIFilter = showPIIFilterVal ? { showPII: true } : {}
  const activationMethodValues = getPathValue(activationMethod)

  const [statusCheckFailCount, setStatusCheckFailCount] = useState<number>(0)
  const [columnPreference, setColumnPreference] = useState<ColumnPreference>(
    () => (localStorage.getItem('columnPreference') as ColumnPreference) ?? 'visibleColumns'
  )

  const getFeedbackFields = () =>
    columnPreference === 'allColumns' || !activeColumnHeaders ? [] : activeColumnHeaders
  const feedbackFields = getFeedbackFields()

  const [requestCSVfromAPI, { error: csvExportError, data: csvExportData }] = useLazyQuery(
    GET_FEEDBACK_CSV,
    {
      client,
      variables: {
        fileName,
        applicationIDs,
        period,
        ...formattedCategoryFilter,
        ...manualCategoryFilter,
        searchBy,
        ...searchText,
        ...sourceQuery,
        ...isReviewed,
        ...showPIIFilter,
        appVersion,
        includeHidden,
        includeNullVerbatims,
        isFlagged,
        isMobileUser,
        reroutePage,
        surveyID,
        isUserSelectedCategory,
        minVerbatimLength,
        maxVerbatimLength,
        totalCount: count,
        feedbackFields,
        CSAT,
        NPS,
        starRating,
        starRatingExternal,
        SR,
        awsSentimentPositive,
        awsSentimentNegative,
        userType,
        pageName,
        accountType,
        billerType,
        billingServices,
        userRole,
        accountExpired,
        autoPayStatus,
        footprint,
        loggedInStatus,
        SCP,
        subscribedServices,
        activationMethod: activationMethodValues,
        userSelectedCategories,
        _id,
        activatedExperimentUuids,
        experimentUuids,
        variantUuids,
        language,
        middleCategory,
      },
      fetchPolicy: 'network-only',
    }
  )

  const { error: csvExportStatusError, data: csvExportStatus } = useQuery(GET_CSV_EXPORT_RECORD, {
    client: csvClient,
    variables: { _id: csvExportRecordID },
    pollInterval: CSV_STATUS_POLLING_INTERVAL,
    skip: isEmpty(csvExportRecordID) || !isEmpty(csvExportURL),
  })

  useEffect(() => {
    const handleCSVRequest = () => {
      if (csvExportInProgress && !csvExportRecordID) {
        requestCSVfromAPI()
      }
    }

    if (!errorMessage) handleCSVRequest()
  }, [errorMessage, csvExportInProgress, csvExportRecordID, requestCSVfromAPI])

  useEffect(() => {
    const startPollingCSVDocument = () => {
      if (csvExportData && csvExportData.getFeedbackCSV) {
        setCSVExportRecordID(csvExportData.getFeedbackCSV)
        setCSVExportInProgress(false)
      }
    }

    startPollingCSVDocument()
  }, [csvExportData, setCSVExportRecordID, setCSVExportInProgress])

  useEffect(() => {
    let timeout: NodeJS.Timeout
    const handleCSVError = () => {
      // Handle Lazy query error
      if (csvExportError) {
        setErrorMessage(csvExportError?.message)
        return
      }

      // Handle Polling query error
      if (csvExportStatusError) {
        const error = csvExportStatusError?.message

        if (`${error}`.includes('Failed to fetch') && statusCheckFailCount < 5) {
          timeout = setTimeout(() => {
            setStatusCheckFailCount((count: number) => count + 1)
          }, CSV_STATUS_POLLING_INTERVAL)
        } else {
          setErrorMessage(error)
        }
      }
    }

    handleCSVError()
    return () => clearTimeout(timeout)
  }, [csvExportError, csvExportStatusError, statusCheckFailCount, setErrorMessage])

  useEffect(() => {
    const handleSetCSVURL = () => {
      if (csvExportStatus && csvExportStatus.getCSVExportRecord) {
        const url = csvExportStatus.getCSVExportRecord.url

        if (!isNil(url)) {
          setCSVExportURL(url)
        }
      }
    }

    handleSetCSVURL()
  }, [csvExportStatus, setCSVExportURL])

  function onClose() {
    setOpen(false)
    setCSVExportInProgress(false)
    setCSVExportURL('')
    setCSVExportRecordID('')
    setErrorMessage('')
  }

  function onDialogClose(event: HTMLDivElement, reason: string) {
    if (
      (csvExportInProgress || csvExportURL) &&
      (reason === 'escapeKeyDown' || reason === 'backdropClick')
    )
      return
    onClose()
  }

  function onContinue() {
    setCSVExportInProgress(true)
  }

  function handleChange() {
    const newPreference = columnPreference === 'visibleColumns' ? 'allColumns' : 'visibleColumns'
    setColumnPreference(newPreference)
    localStorage.setItem('columnPreference', newPreference)
  }

  const countDisplay = count === 1 ? `1 row` : `${numberWithCommas(count)} rows`

  const modalMessage = csvExportURL
    ? `CSV has finished processing with ${countDisplay}, click the link below to download`
    : 'Depending on the amount of feedback, it could take up to 15 minutes to process. A link to download the CSV will display here once the file is available.'

  const ModalContents = () => (
    <>
      <S.StyledDialogText data-pho="csvModalAlert">{modalMessage}</S.StyledDialogText>
      {csvExportInProgress ? (
        <div style={{ overflow: 'hidden' }}>
          <LoadingSpinner />
        </div>
      ) : (
        <>
          {csvExportURL ? (
            <S.StyledDownloadLink href={csvExportURL} data-pho="csvModalDownloadLink">
              {csvExportURL.slice(0, 100)}...
            </S.StyledDownloadLink>
          ) : (
            <>
              <S.StyledDialogText>The CSV will include {countDisplay}</S.StyledDialogText>
              {columnPreference && (
                <FormControl component="fieldset">
                  <RadioGroup
                    name="columnPrefs"
                    value={columnPreference}
                    onChange={handleChange}
                    data-pho="csvModalRadioGroup"
                  >
                    <FormControlLabel
                      value="allColumns"
                      control={<Radio />}
                      label="All Columns"
                      data-pho="csvModalAllColumns"
                    />
                    <FormControlLabel
                      value="visibleColumns"
                      control={<Radio />}
                      label="Visible Columns Only"
                      data-pho="csvModalVisibleColumns"
                    />
                  </RadioGroup>
                </FormControl>
              )}
            </>
          )}
        </>
      )}
    </>
  )

  const ExportButton = () => {
    const hideButton = !csvExportURL && !errorMessage
    return (
      <>
        {hideButton && (
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={onContinue}
            data-pho="csvModalContinueButton"
          >
            Export
          </Button>
        )}
      </>
    )
  }

  return (
    <div>
      <S.StyledDialog
        open={open || csvExportInProgress || !!csvExportURL}
        onClose={onDialogClose}
        data-pho="downloadCSVModal"
      >
        <S.StyledDialogTitle data-pho="csvModalTitle">Export to CSV</S.StyledDialogTitle>
        <S.StyledDialogContent>
          {errorMessage ? (
            <Alert severity="error">An error has occurred processing the CSV: {errorMessage}</Alert>
          ) : (
            <ModalContents />
          )}
        </S.StyledDialogContent>
        <S.StyledDialogActions>
          {(!csvExportInProgress || errorMessage) && (
            <>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                onClick={onClose}
                data-pho="csvModalCancelButton"
              >
                Close
              </Button>
              <ExportButton />
            </>
          )}
        </S.StyledDialogActions>
      </S.StyledDialog>
    </div>
  )
}
