import React, { useState, useEffect } from 'react'
import { useLocation } from '@reach/router'
import extensions from '@charter/helix/build/extensions'
import analytics from 'utils/analytics'
import useHeader from 'hooks/useHeader'
import HelixAutoTracker from '@charter/helix/build/modules/autoTracker'
import useGroups from 'hooks/useGroups'
import useApps from 'hooks/useApps'
import isEmpty from 'lodash/isEmpty'

function Analytics({ children }: { children: React.ReactNode }) {
  const [autoTrack, setAutoTrack] = useState(null)
  const {
    title: headerTitle,
    pageName,
    pageTitle,
    areFiltersReady,
    pageType: pageTypeHeader,
  } = useHeader().state
  const { currentGroup } = useGroups()
  const { entityID } = useApps()

  useEffect(() => {
    if (!autoTrack) {
      const autoTracker = HelixAutoTracker(analytics.track).trackByObserverPlugin
      setAutoTrack(autoTracker.init())
    }
  }, [autoTrack])

  const location = useLocation()

  const currPageAppSection = analytics.getCurrentPageAppSection(location)

  const getPageType = () => {
    if (currentGroup) {
      return 'Groups'
    } else if (entityID) {
      return 'Applications'
    } else {
      return 'Other'
    }
  }
  const pageType = pageTypeHeader ?? getPageType()

  // Auto Page View Tracking
  React.useEffect(() => {
    if (!isEmpty(pageName)) {
      analytics.trackPageViewInit({
        currPageUrl: window.location.href,
        currPageTitle: pageTitle || headerTitle || '',
        currPageAppSection,
        currPageName: pageName,
        currPagePageType: pageType,
        currPageIsLazyLoad: false,
        msgTriggeredBy: 'user',
        uiResponsiveBreakpoint: extensions.getCurrentBreakpoint(),
      })
    }
  }, [pageTitle, headerTitle, pageName, pageType]) // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (pageName) {
      analytics.trackPageView()
    }
  }, [pageName]) // eslint-disable-line react-hooks/exhaustive-deps

  return <>{children}</>
}

export default Analytics
