import { useEffect, useState } from 'react'
import useAuth from './useAuth'

export const useTopTalkerAccess = () => {
  const [hasTopTalkerAccess, setHasTopTalkerAccess] = useState(false)
  const auth = useAuth()
  const roles = auth.user?.roles

  useEffect(() => {
    if (roles) {
      setHasTopTalkerAccess(roles?.includes('prism-admin'))
    }
  }, [roles])

  return { hasTopTalkerAccess }
}
