import React, { useState } from 'react'
import * as S from './styles'
import * as U from './utils'
import { DarkModeProvider } from 'components/App/Providers/DarkMode'
import Flex from 'components/shared/flex'
import Icon from 'components/shared/icon'
import Typography from 'components/shared/typography'
import { IconButton } from 'components/shared/IconButton'
import Link from 'components/shared/Link/PrismReactLink'
import { useLocation } from '@reach/router'
import MuiAlert from '@material-ui/lab/Alert'
import { Box } from '@material-ui/core'
export interface AlertProps extends React.PropsWithoutRef<JSX.IntrinsicElements['div']> {
  /** The alert text. (Limited to 120 characters.) */
  children: string
  /** The type and status of alert to use. */
  variant?:
    | 'global-error'
    | 'global-caution'
    | 'global-info'
    | 'page-error'
    | 'page-caution'
    | 'page-info'
    | 'page-success'
  /** Whether or not the alert can be dismissed by the user. (It is usually bad UX to make an alert both persistent and "fixed," as it may overlap navigation links or other content.) */
  isPersistent?: boolean
  /** Whether or not the alert overlaps content, fixed at the top of the screen or container. (Beware, this can cover up top bars where most global navigation lives. You might consider leaving this false and putting the global alert above your topbar, pushing it down.) */
  isFixed?: boolean
  /** Provide external links, here. If your link goes to an internal page in your React app, use linkTo, instead. */
  linkHref?: string
  /** Prism React uses Reach Router for its React navigation. Provide a route handled by Reach Router here (ie: '/my/route'). */
  linkTo?: string
  /** (Not recommended.) Use this to hijack the onClick to do something else when the link is clicked. */
  linkOnClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void
  /** The label for the link. If a linkHref or linkTo is provided, but no label, "Learn more" will be used. */
  linkLabel?: string
  /** A callback function that is triggered when the close button is clicked. */
  onClose?: any
  /** Space between an element's border and the element's content. */
  padding?: string
  /** Dictates the color of container for Mui Alert */
  severity?: 'error' | 'warning' | 'info' | 'success'
}

export default ({
  children,
  variant = 'global-error',
  isPersistent = false,
  isFixed = false,
  linkHref,
  linkTo,
  linkLabel,
  linkOnClick,
  padding,
  severity,
  onClose,
}: AlertProps) => {
  const text = children.slice(0, 120)
  const { pathname } = useLocation()
  const isVoC = pathname.includes('voice-of-customer')
  const [alertOpen, setAlertOpen] = useState<boolean>(true)
  const handleClose = () => {
    setAlertOpen(false)
  }

  if (isVoC) {
    return (
      <Box mb={alertOpen ? 2 : 0} padding={padding}>
        <MuiAlert
          hidden={!alertOpen}
          severity={severity ?? 'info'}
          
          onClose={onClose ? onClose : () => setAlertOpen(false)} 
        >
          {children}
        </MuiAlert>
      </Box>
    )
  }

  if (variant.includes('global')) {
    return alertOpen ? (
      <DarkModeProvider startDark={variant === 'global-error'}>
        <S.GlobalAlert $variant={variant} $isFixed={isFixed} column padding="8px 16px">
          <Flex
            alignItems="center"
            height="42px"
            padding="1px 0"
            justifyContent="space-between"
            marginBetween="8px"
          >
            <Flex alignItems="center" marginBetween="16px">
              <Icon
                name={U.getAlertIcon(variant)}
                color={variant !== 'global-error' && ('black' as any)}
                margin={{ bottom: '4px' }}
              />
              <Typography variant="body" noMargin>
                {text}
              </Typography>
            </Flex>
            {!isPersistent && (
              <IconButton
                icon="XAlt"
                size="sm"
                color={variant !== 'global-error' && ('black' as any)}
                onClick={handleClose}
              />
            )}
          </Flex>
          {(linkHref || linkTo || linkOnClick) && (
            <Link
              style={{ margin: '0 0 8px 36px' }}
              variant="standalone"
              icon
              href={linkHref}
              to={linkTo}
              onClick={linkOnClick}
              disableVisited
            >
              {linkLabel}
            </Link>
          )}
        </S.GlobalAlert>
      </DarkModeProvider>
    ) : (
      <></>
    )
  }
  return alertOpen ? (
    <S.PageAlert disablePadding $variant={variant} $isFixed={isFixed} elevation={1}>
      <Flex alignItems="stretch">
        <S.Stripe $variant={variant} />
        <Flex width="100%" column padding="8px">
          <Flex alignItems="center" height="42px" padding="1px 0" justifyContent="space-between">
            <Flex alignItems="center" marginBetween="8px">
              <Icon
                name={U.getAlertIcon(variant)}
                color={U.getAlertColor(variant)}
                margin={{ bottom: '4px' }}
              />
              <Typography variant="body" noMargin>
                {text}
              </Typography>
            </Flex>
            {!isPersistent && (
              <IconButton
                icon="XAlt"
                size="sm"
                color={variant !== 'global-error' && ('black' as any)}
                onClick={handleClose}
              />
            )}
          </Flex>
          {(linkHref || linkTo || linkOnClick) && (
            <Link
              style={{ margin: '0 0 8px 28px' }}
              variant="standalone"
              icon
              href={linkHref}
              to={linkTo}
              onClick={linkOnClick}
              disableVisited
            >
              {linkLabel}
            </Link>
          )}
        </Flex>
      </Flex>
    </S.PageAlert>
  ) : (
    <></>
  )
}
