import React from 'react'
import sortBy from 'lodash/sortBy'
import { useMutation } from '@apollo/client'
import Tooltip from '@material-ui/core/Tooltip'
import useFilterValues from 'hooks/useFilterValues'
import useAuth from 'hooks/useAuth'
import { getVocClient } from 'components/voiceOfCustomer/utils'
import { mutations } from 'components/voiceOfCustomer'
import PopoverMenu from 'components/shared/popover'
import TableViewDialog from './TableViewDialog'
import { Button } from 'components/shared/Button'
import { IconButton } from 'components/shared/IconButton'
import { defaultColumns, reviewsView, detailsView } from '../constants'
import { getCurrentOrder } from '../utils'
import * as T from '../types'
import * as S from './styles'

function TableSettings({
  columnGroups,
  selected,
  columnGroupFavoriteID,
  permissionId,
  setColumnGroupFavoriteID,
  detailsTable,
  refetchGetUser,
  handleViewChange,
  setFavoriteIDFlag,
}: ColumnSelectorProps) {
  const auth = useAuth()
  const authID = auth?.user?.id ?? ''

  const { environment } = useFilterValues()
  const client = getVocClient(environment as string)
  const [deleteColumnGroup] = useMutation(mutations.DELETE_COLUMN_GROUP, {
    client,
  })
  const [updateUserDefaultColumnGroup] = useMutation(mutations.UPDATE_USER_DEFAULT_COLUMN_GROUP, {
    client,
  })

  async function saveUserFavorite(columnGroupID?: string) {
    setColumnGroupFavoriteID(columnGroupID)
    await updateUserDefaultColumnGroup({
      variables: {
        _id: permissionId,
        columnGroupID,
      },
    })
  }

  async function handleSaveUserFavorite(columnGroupID: string) {
    saveUserFavorite(columnGroupID === columnGroupFavoriteID ? '' : columnGroupID)
    refetchGetUser()
  }

  async function handleDelete(id: string) {
    if (id === columnGroupFavoriteID) {
      saveUserFavorite(detailsTable ? detailsView.value : reviewsView.value)
    }
    await deleteColumnGroup({
      variables: {
        authID,
        _id: id,
      },
    })
    refetchGetUser()
  }

  function handleViewButtonClick(
    id: string,
    handleClose: Function,
    updatedColumnLabels?: string[]
  ) {
    handleViewChange(id, updatedColumnLabels)
    handleClose()
    if (updatedColumnLabels) {
      setFavoriteIDFlag(false)
    }
  }

  return (
    <div>
      <PopoverMenu
        key="tableSettings"
        anchorContent={
          <Tooltip title="Add/Edit my custom table views" placement="bottom">
            <Button icon="OnDemand" variant="borderless">
              Views & Columns
            </Button>
          </Tooltip>
        }
      >
        {({ handleClose }: { handleClose: Function }) => (
          <S.EditViewsContainer>
            <S.StyledViewContainer>
              <S.Title>My Custom Views</S.Title>
              {sortBy(columnGroups, 'name').map((columnGroup: T.ColumnGroup) => {
                const { _id: id, name, columns } = columnGroup
                const defaultOptions = columns.map((column: T.UserColumn) => column.name)
                const currentOrder = getCurrentOrder(columnGroup)

                return (
                  <S.ViewContainer>
                    <S.TitleContainer>
                      <Tooltip
                        title="Select as your favorite for the current app"
                        placement="bottom"
                      >
                        <div>
                          <IconButton
                            icon={id === columnGroupFavoriteID ? 'StarF' : 'Star'}
                            size="sm"
                            onClick={() => handleSaveUserFavorite(id)}
                          />
                        </div>
                      </Tooltip>
                      <Button
                        width="13rem"
                        variant={selected === id ? 'secondary' : 'borderless'}
                        onClick={() => handleViewButtonClick(id, handleClose)}
                        data-pho={name}
                      >
                        {name}
                      </Button>
                    </S.TitleContainer>
                    <S.ViewActions>
                      <TableViewDialog
                        id={id}
                        name={name}
                        isPatch
                        currentOrder={currentOrder}
                        defaultOptions={defaultOptions}
                        handleSelectView={(updatedColumnLabels: string[]) =>
                          handleViewButtonClick(id, handleClose, updatedColumnLabels)
                        }
                        handleCloseSettings={handleClose}
                        refetchGetUser={refetchGetUser}
                      />
                      {selected === id ? (
                        <Tooltip
                          title="You can't delete view you are currently using"
                          placement="bottom"
                        >
                          <div>
                            <IconButton icon="TrashF" size="sm" disabled />
                          </div>
                        </Tooltip>
                      ) : (
                        <IconButton
                          icon="TrashF"
                          size="sm"
                          disabled={selected === id}
                          onClick={() => handleDelete(id)}
                        />
                      )}
                    </S.ViewActions>
                  </S.ViewContainer>
                )
              })}
              <S.ViewContainer>
                <TableViewDialog
                  isPatch={false}
                  handleCloseSettings={handleClose}
                  refetchGetUser={refetchGetUser}
                />
              </S.ViewContainer>
            </S.StyledViewContainer>
            <S.Title>Default Views</S.Title>
            {defaultColumns.map((columnGroup: T.DefaultColumnGroup) => {
              const { value: id, label: name } = columnGroup
              return (
                <S.ViewContainer>
                  <S.TitleContainer>
                    <Tooltip title="Select as your favorite for the current app" placement="bottom">
                      <div>
                        <IconButton
                          icon={id === columnGroupFavoriteID ? 'StarF' : 'Star'}
                          size="sm"
                          onClick={() => handleSaveUserFavorite(id)}
                        />
                      </div>
                    </Tooltip>
                    <Button
                      width="13rem"
                      variant={selected === id ? 'secondary' : 'borderless'}
                      onClick={() => handleViewButtonClick(id, handleClose)}
                      data-pho={name}
                    >
                      {name}
                    </Button>
                  </S.TitleContainer>
                </S.ViewContainer>
              )
            })}
          </S.EditViewsContainer>
        )}
      </PopoverMenu>
    </div>
  )
}

interface ColumnSelectorProps {
  columnGroups: T.ColumnGroup[]
  selected: string
  columnGroupFavoriteID?: string
  permissionId?: string
  setColumnGroupFavoriteID: Function
  detailsTable?: boolean
  refetchGetUser: Function
  handleViewChange: Function
  setFavoriteIDFlag: Function
}

export default TableSettings
