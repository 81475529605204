import { Toast } from 'components/shared/Toast'
import Topbar from 'components/layout/Topbar'
import useSite from 'hooks/useSite'
import useVersion from 'hooks/useVersion'
import React, { useState, lazy, Suspense } from 'react'
import 'react-base-table/styles.css'
import { RecoilRoot } from 'recoil'
import Screens from 'screens'
import NotFoundScreen from 'screens/404'
import analytics from 'utils/analytics'
import Providers from './Providers'
import * as S from './styles'
import { LoadingSpinner } from 'components/shared/LoadingSpinner'
import { Router } from '@reach/router'

const AsyncSurveyScreen = lazy(() => import('screens/tools/cst/respondent_portal'))
const AsyncUploadScreen = lazy(() => import('screens/tools/cst/respondent_portal/SurveyUpload'))

function App() {
  const pathname = window.location.pathname.slice(0, 7)
  const search = window.location.search.slice(0, 4)

  const surveyPage = pathname === '/survey'
  const fileUploadPage = pathname === '/upload' && search === '?id='

  const { version } = useSite()
  const isLatestVersion = useVersion(version)
  const [menuClosed, setMenuClosed] = useState(false)

  const handleSetMenuClosed = React.useCallback(() => setMenuClosed(true), [setMenuClosed])

  if (fileUploadPage) {
    return (
      <RecoilRoot>
        <Suspense fallback={<LoadingSpinner />}>
          <Router primary={false}>
            <AsyncUploadScreen path="upload" />
          </Router>
        </Suspense>
      </RecoilRoot>
    )
  }

  return (
    <Providers>
      <RecoilRoot>
        <Toast
          label="A new version of Prism is available. Refresh to view."
          isToastOpen={!isLatestVersion && !menuClosed}
          icon="CautionAlert"
          placement="top-right"
          duration={10}
          onDismiss={handleSetMenuClosed}
        />
        {surveyPage ? (
          <Suspense fallback={<LoadingSpinner />}>
            <Router primary={false}>
              <AsyncSurveyScreen path="survey/*" />
            </Router>
          </Suspense>
        ) : (
          <S.AppContainer>
            <Topbar />
            <Screens />
          </S.AppContainer>
        )}
      </RecoilRoot>
    </Providers>
  )
}

class AppHandler extends React.Component<React.ComponentClass, React.ComponentState> {
  constructor(props: any) {
    super(props)
    this.state = { hasError: false, error: null }
  }
  static getDerivedStateFromError(error: any) {
    return { hasError: true, error }
  }
  componentDidCatch(error: Error) {
    const { message } = error

    analytics.track('error', {
      appErrorType: 'generic',
      appClientErrorCode: 'componentDidCatchError',
      ...(message ? { appErrorMessage: message } : {}),
    })
  }

  render() {
    const { hasError = false } = this.state
    if (hasError) {
      return (
        <NotFoundScreen
          title={`Error: ${this.state.error.message}`.toUpperCase() || 'An unknown error occurred'}
          error
          copy={''}
        />
      )
    }

    return <App />
  }
}

export default AppHandler
